/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  ICardTokenScoring,
  ICardTokenScoringInterface,
} from "../ICardTokenScoring";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenSupply",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "scoreMin",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "scoreMax",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "scoreScale",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "scoreMaxTokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "calculateScore",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

export class ICardTokenScoring__factory {
  static readonly abi = _abi;
  static createInterface(): ICardTokenScoringInterface {
    return new utils.Interface(_abi) as ICardTokenScoringInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ICardTokenScoring {
    return new Contract(address, _abi, signerOrProvider) as ICardTokenScoring;
  }
}
