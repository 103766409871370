/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { ICardAuction, ICardAuctionInterface } from "../ICardAuction";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "lsp8Contract",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "tokenId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "bidder",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bidAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "endTime",
        type: "uint256",
      },
    ],
    name: "AuctionBid",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "lsp8Contract",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "tokenId",
        type: "bytes32",
      },
    ],
    name: "AuctionCancel",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "lsp8Contract",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "tokenId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "auctionWinner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bidAmount",
        type: "uint256",
      },
    ],
    name: "AuctionFinalize",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "lsp8Contract",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "tokenId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "acceptedToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minimumBid",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "endTime",
        type: "uint256",
      },
    ],
    name: "AuctionOpen",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "lsp8Contract",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "tokenId",
        type: "bytes32",
      },
    ],
    name: "auctionFor",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "seller",
            type: "address",
          },
          {
            internalType: "address",
            name: "acceptedToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "minimumBid",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "endTime",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "activeBidder",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "activeBidAmount",
            type: "uint256",
          },
        ],
        internalType: "struct ICardAuction.CardAuctionState",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "auctionSettings",
    outputs: [
      {
        components: [
          {
            internalType: "uint24",
            name: "minAuctionDuration",
            type: "uint24",
          },
          {
            internalType: "uint24",
            name: "maxAuctionDuration",
            type: "uint24",
          },
          {
            internalType: "uint24",
            name: "bidExtensionDuration",
            type: "uint24",
          },
        ],
        internalType: "struct ICardAuction.AuctionSettings",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "lsp8Contract",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "tokenId",
        type: "bytes32",
      },
    ],
    name: "cancelAuctionFor",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "claimToken",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "claimableAmountsFor",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "lsp8Contract",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "tokenId",
        type: "bytes32",
      },
    ],
    name: "finalizeAuctionFor",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getAllAuctions",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "lsp8Contract",
            type: "address",
          },
          {
            components: [
              {
                internalType: "bytes32",
                name: "tokenId",
                type: "bytes32",
              },
              {
                components: [
                  {
                    internalType: "address",
                    name: "seller",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "acceptedToken",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "minimumBid",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "endTime",
                    type: "uint256",
                  },
                  {
                    internalType: "address",
                    name: "activeBidder",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "activeBidAmount",
                    type: "uint256",
                  },
                ],
                internalType: "struct ICardAuction.CardAuctionState",
                name: "auction",
                type: "tuple",
              },
            ],
            internalType: "struct ICardAuction.AuctionForTokenId[]",
            name: "auctions",
            type: "tuple[]",
          },
        ],
        internalType: "struct ICardAuction.AuctionsForLSP8Contract[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "lsp8Contract",
        type: "address",
      },
    ],
    name: "getAuctionsForLSP8Contract",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "lsp8Contract",
            type: "address",
          },
          {
            components: [
              {
                internalType: "bytes32",
                name: "tokenId",
                type: "bytes32",
              },
              {
                components: [
                  {
                    internalType: "address",
                    name: "seller",
                    type: "address",
                  },
                  {
                    internalType: "address",
                    name: "acceptedToken",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "minimumBid",
                    type: "uint256",
                  },
                  {
                    internalType: "uint256",
                    name: "endTime",
                    type: "uint256",
                  },
                  {
                    internalType: "address",
                    name: "activeBidder",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "activeBidAmount",
                    type: "uint256",
                  },
                ],
                internalType: "struct ICardAuction.CardAuctionState",
                name: "auction",
                type: "tuple",
              },
            ],
            internalType: "struct ICardAuction.AuctionForTokenId[]",
            name: "auctions",
            type: "tuple[]",
          },
        ],
        internalType: "struct ICardAuction.AuctionsForLSP8Contract",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "lsp8Contract",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "tokenId",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "acceptedToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "minimumBid",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "duration",
        type: "uint256",
      },
    ],
    name: "openAuctionFor",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint24",
        name: "minAuctionDuration",
        type: "uint24",
      },
      {
        internalType: "uint24",
        name: "maxAuctionDuration",
        type: "uint24",
      },
      {
        internalType: "uint24",
        name: "bidExtensionDuration",
        type: "uint24",
      },
    ],
    name: "setAuctionSettings",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "lsp8Contract",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "tokenId",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "submitBid",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class ICardAuction__factory {
  static readonly abi = _abi;
  static createInterface(): ICardAuctionInterface {
    return new utils.Interface(_abi) as ICardAuctionInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ICardAuction {
    return new Contract(address, _abi, signerOrProvider) as ICardAuction;
  }
}
