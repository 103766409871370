/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  RoyaltySharesProxy,
  RoyaltySharesProxyInterface,
} from "../RoyaltySharesProxy";

const _abi = [
  {
    inputs: [],
    name: "RoyaltySharesRoyaltiesRequired",
    type: "error",
  },
  {
    inputs: [],
    name: "RoyaltySharesRoyaltiesSum",
    type: "error",
  },
  {
    inputs: [],
    name: "royaltyShares",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "receiver",
            type: "address",
          },
          {
            internalType: "uint96",
            name: "share",
            type: "uint96",
          },
        ],
        internalType: "struct RoyaltySharesLib.RoyaltyShare[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class RoyaltySharesProxy__factory {
  static readonly abi = _abi;
  static createInterface(): RoyaltySharesProxyInterface {
    return new utils.Interface(_abi) as RoyaltySharesProxyInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): RoyaltySharesProxy {
    return new Contract(address, _abi, signerOrProvider) as RoyaltySharesProxy;
  }
}
