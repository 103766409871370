/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  Create2Factory,
  Create2FactoryInterface,
} from "../Create2Factory";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "addr",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "salt",
        type: "bytes32",
      },
    ],
    name: "ContractCreated",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "salt",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "bytecodeHash",
        type: "bytes32",
      },
    ],
    name: "computeAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "salt",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "bytecodeHash",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "deployer",
        type: "address",
      },
    ],
    name: "computeAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "salt",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "bytecode",
        type: "bytes",
      },
    ],
    name: "deploy",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b5061038a806100206000396000f3fe6080604052600436106100345760003560e01c8063481286e6146100395780637806530614610075578063cdcb760a14610095575b600080fd5b34801561004557600080fd5b5061005961005436600461021c565b6100a8565b6040516001600160a01b03909116815260200160405180910390f35b34801561008157600080fd5b5061005961009036600461023e565b6100bc565b6100596100a3366004610283565b610117565b60006100b58383306100bc565b9392505050565b604080516001600160f81b031960208083019190915260609390931b6bffffffffffffffffffffffff191660218201526035810194909452605580850193909352805180850390930183526075909301909252805191012090565b8051600090819034906101715760405162461bcd60e51b815260206004820181905260248201527f437265617465323a2062797465636f6465206c656e677468206973207a65726f60448201526064015b60405180910390fd5b8484516020860183f591506001600160a01b0382166101d25760405162461bcd60e51b815260206004820152601960248201527f437265617465323a204661696c6564206f6e206465706c6f79000000000000006044820152606401610168565b604080516001600160a01b0384168152602081018790527fc16bb3dbd36917c7aa3e76b988c2cd35e74bb230a02fef61e7376d8b4bfaea77910160405180910390a1509392505050565b6000806040838503121561022f57600080fd5b50508035926020909101359150565b60008060006060848603121561025357600080fd5b833592506020840135915060408401356001600160a01b038116811461027857600080fd5b809150509250925092565b6000806040838503121561029657600080fd5b82359150602083013567ffffffffffffffff808211156102b557600080fd5b818501915085601f8301126102c957600080fd5b8135818111156102db576102db61033e565b604051601f8201601f19908116603f011681019083821181831017156103035761030361033e565b8160405282815288602084870101111561031c57600080fd5b8260208601602083013760006020848301015280955050505050509250929050565b634e487b7160e01b600052604160045260246000fdfea264697066735822122023c3d212c2a1d702b2e42e9c0d61eaac9ccf864e4dcf70b037f203f4de42f7eb64736f6c63430008060033";

export class Create2Factory__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<Create2Factory> {
    return super.deploy(overrides || {}) as Promise<Create2Factory>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): Create2Factory {
    return super.attach(address) as Create2Factory;
  }
  connect(signer: Signer): Create2Factory__factory {
    return super.connect(signer) as Create2Factory__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): Create2FactoryInterface {
    return new utils.Interface(_abi) as Create2FactoryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): Create2Factory {
    return new Contract(address, _abi, signerOrProvider) as Create2Factory;
  }
}
