/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  ILSP1UniversalReceiver,
  ILSP1UniversalReceiverInterface,
} from "../ILSP1UniversalReceiver";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "typeId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes",
        name: "returnedValue",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "receivedData",
        type: "bytes",
      },
    ],
    name: "UniversalReceiver",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "typeId",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "universalReceiver",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class ILSP1UniversalReceiver__factory {
  static readonly abi = _abi;
  static createInterface(): ILSP1UniversalReceiverInterface {
    return new utils.Interface(_abi) as ILSP1UniversalReceiverInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ILSP1UniversalReceiver {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as ILSP1UniversalReceiver;
  }
}
