/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IFeeCollector, IFeeCollectorInterface } from "../IFeeCollector";

const _abi = [
  {
    inputs: [],
    name: "baseRevenueShareFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "feeBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "platformFeeReceiver",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "revenueShareFees",
    outputs: [
      {
        components: [
          {
            internalType: "uint16",
            name: "platform",
            type: "uint16",
          },
          {
            internalType: "uint16",
            name: "creator",
            type: "uint16",
          },
          {
            internalType: "uint16",
            name: "referral",
            type: "uint16",
          },
        ],
        internalType: "struct IFeeCollector.RevenueShareFees",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "referrer",
        type: "address",
      },
      {
        components: [
          {
            internalType: "address",
            name: "receiver",
            type: "address",
          },
          {
            internalType: "uint96",
            name: "share",
            type: "uint96",
          },
        ],
        internalType: "struct RoyaltySharesLib.RoyaltyShare[]",
        name: "creatorRoyalties",
        type: "tuple[]",
      },
      {
        internalType: "bytes",
        name: "dataForCallback",
        type: "bytes",
      },
    ],
    name: "shareRevenue",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "tokenList",
        type: "address[]",
      },
    ],
    name: "withdrawTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "addressList",
        type: "address[]",
      },
      {
        internalType: "address[]",
        name: "tokenList",
        type: "address[]",
      },
    ],
    name: "withdrawTokensForMany",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IFeeCollector__factory {
  static readonly abi = _abi;
  static createInterface(): IFeeCollectorInterface {
    return new utils.Interface(_abi) as IFeeCollectorInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IFeeCollector {
    return new Contract(address, _abi, signerOrProvider) as IFeeCollector;
  }
}
