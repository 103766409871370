/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IRoyaltyShares,
  IRoyaltySharesInterface,
} from "../IRoyaltyShares";

const _abi = [
  {
    inputs: [],
    name: "royaltyShares",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "receiver",
            type: "address",
          },
          {
            internalType: "uint96",
            name: "share",
            type: "uint96",
          },
        ],
        internalType: "struct RoyaltySharesLib.RoyaltyShare[]",
        name: "royaltiesForAsset",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IRoyaltyShares__factory {
  static readonly abi = _abi;
  static createInterface(): IRoyaltySharesInterface {
    return new utils.Interface(_abi) as IRoyaltySharesInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IRoyaltyShares {
    return new Contract(address, _abi, signerOrProvider) as IRoyaltyShares;
  }
}
