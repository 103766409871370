/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  ImportLSP1UniversalReceiverDelegateUP,
  ImportLSP1UniversalReceiverDelegateUPInterface,
} from "../ImportLSP1UniversalReceiverDelegateUP";

const _abi = [
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "typeId",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "universalReceiverDelegate",
    outputs: [
      {
        internalType: "bytes",
        name: "result",
        type: "bytes",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class ImportLSP1UniversalReceiverDelegateUP__factory {
  static readonly abi = _abi;
  static createInterface(): ImportLSP1UniversalReceiverDelegateUPInterface {
    return new utils.Interface(
      _abi
    ) as ImportLSP1UniversalReceiverDelegateUPInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ImportLSP1UniversalReceiverDelegateUP {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as ImportLSP1UniversalReceiverDelegateUP;
  }
}
