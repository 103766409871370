export default Object.freeze({
  SupportedStandardsERC725Account:
    '0xeafec4d89fa9619884b6b89135626455000000000000000000000000afdeb5d6',
  LSP1DELEGATE:
    '0x0cfc51aec37c55a4d0b1a65c6255c4bf2fbdf6277f3cc0730c45b828b6db8b47',
  LSP2IssuedAssets:
    '0xb8c4a0b76ed8454e098b20a987a980e69abe3b1a88567ae5472af5f863f8c8f9',
  LSP3PROFILE:
    '0x5ef83ad9559033e6e941db7d7c495acdce616347d28e90c7ce47cbfcfcad3bc5',
  LSP3UniversalProfile:
    '0xeafec4d89fa9619884b6b89135626455000000000000000000000000afdeb5d6',
  LSP3IssuedAssets:
    '0x3a47ab5bd3a594c3a8995f8fa58d0876c96819ca4516bd76100c92462f2f9dc0',
  LSP4DigitalCertificate:
    '0xeafec4d89fa9619884b6b89135626455000000000000000000000000abf0613c',
  LSP4Metadata:
    '0x9afb95cacc9f95858ec44aa8c3b685511002e30ae54415823f406128b85b238e',
  LSP4Type:
    '0x4cd61d42bec47e5be0fa92cb767c0a01f91af591cee430423287127fe58b66ca',
  LSP4Assets:
    '0x5fa8d8247112f88f035d746484935915caa778a049c0927c00bb1c2696497a95',
  LSP4Description:
    '0xfc5327884a7fb1912dcdd0d78d7e6753f03e61a8e0b845a4b62f5efde472d0a8',
  LSP4Images:
    '0x150834e6d4fd704dc914e5372942f0615863fd9d206030643c2a6391dc6ddbf1',
  LSP5ReceivedAssets:
    '0x6460ee3c0aac563ccbf76d6e1d07bada78e3a9514e6382b736ed3f478ab7b90b',
  LSP5DigitalCloth:
    '0x400823a66792632b83426dae64ca619a7b8ffcde4f406c2fb8fd5d0a62286b42',
  LSP4Creators:
    '0x114bd03b3a46d48759680d81ebb2b414fda7d030a7105a851867accf1c2352e7',
  SupportedStandards:
    '0xeafec4d89fa9619884b6b89135626455000000000000000000000000abf0613c',
  LSP6DigitalCard:
    '0xeafec4d89fa9619884b6b891356264550000000000000000000000009489e346',
  LSP6Metadata:
    '0x9afb95cacc9f95858ec44aa8c3b685511002e30ae54415823f406128b85b238e',
  LSP6UniqueDigitalCollectible:
    '0xeafec4d89fa9619884b6b891356264550000000000000000000000007a21a5c1',
  LSP6AddressPermissions:
    '0xdf30dba06db6a30e65354d9a64c609861f089545ca58c6b4dbe31a5f338cb0e3',
  LSP6AddressPermissions_Permissions: '0x4b80742d0000000082ac0000',
});
