/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  ILSP4Compatibility,
  ILSP4CompatibilityInterface,
} from "../ILSP4Compatibility";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "key",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "value",
        type: "bytes",
      },
    ],
    name: "DataChanged",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "keys",
        type: "bytes32[]",
      },
    ],
    name: "getData",
    outputs: [
      {
        internalType: "bytes[]",
        name: "values",
        type: "bytes[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32[]",
        name: "keys",
        type: "bytes32[]",
      },
      {
        internalType: "bytes[]",
        name: "values",
        type: "bytes[]",
      },
    ],
    name: "setData",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class ILSP4Compatibility__factory {
  static readonly abi = _abi;
  static createInterface(): ILSP4CompatibilityInterface {
    return new utils.Interface(_abi) as ILSP4CompatibilityInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ILSP4Compatibility {
    return new Contract(address, _abi, signerOrProvider) as ILSP4Compatibility;
  }
}
