import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ReduxProvider } from './boot/ReduxProvider';
import { buildApi } from './services/api';
import FontStyles from './fonts/fontStyles';

ReactDOM.render(
  <React.StrictMode>
    <FontStyles />
    <ReduxProvider buildApi={buildApi}>
      <App />
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
